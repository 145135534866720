// Specific styles for displaying rendered markdown on all pages
// This is still selected by div.wiki since this is still used throughout the app.

div.wiki,
div.ck-editor__preview {

  // Style pre tags alone
  pre {
    margin: 1em 1em 1em 1.6em;
    padding: 2px 2px 2px 0;
    background-color: #fafafa;
    border: 1px solid #dadada;
    width: auto;
    overflow-x: auto;
    overflow-y: hidden; }

  // Avoid doubling borders in pre > code
  pre > code {
    display: block;
    border: none;
    background: transparent;
    color: initial; }

  ul.task-list {
    list-style: none;

    .task-list-item {
      * {
        vertical-align: top; }
      input {
        height: 25px;
        position: absolute;
        margin-left: -25px; } } }

  // Style tables within figure.table
  figure.table {
    // Copy the display style of CKEDitors table figures
    // this prevents cutting of the figure if float is involved
    display: table;

    // If a figure is present, it might set the width and height
    // so we want to have the table at full width and height
    > table {
      height: 100%;
      width: 100%;

      .task-list-item {
        input {
          position: relative;
          margin-right: 5px; } } }

    // Ensure we break apart words in table cells that
    // are restricted in width (OP#33524)
    td[style*=";width:"],
    td[style^="width:"],
    th[style*=";width:"],
    th[style^="width:"] {
      word-break: break-all; } } }
