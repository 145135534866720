//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2020 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2017 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
//++

div.wiki {
  // ------------------- FONT -------------------

  font-size: var(--wiki-default-font-size);

  h1 {
    font-size: calc(var(--wiki-default-font-size) * 1.75); }
  h2 {
    font-size: calc(var(--wiki-default-font-size) * 1.75 * 0.8); }
  h3 {
    font-size: calc(var(--wiki-default-font-size) * 1.75 * 0.7); }
  h4 {
    font-size: calc(var(--wiki-default-font-size) * 1.75 * 0.6); }
  h5 {
    font-size: calc(var(--wiki-default-font-size) * 1.75 * 0.5); }

  h1, h2, h3, h4, h5 {
    margin: 1em 0;
    line-height: 1.5;
    font-weight: bold;
    color: #555555;
    text-transform: none;
    border-bottom: 1px dotted #bbbbbb; }

  h1, h2 {
    border-bottom-style: solid; }


  // ------------------- TABLES -------------------
  table:not(.work-package-table) {
    border: 1px solid #bbb;
    border-collapse: collapse;
    margin-bottom: 1em;

    td, th {
      border: 1px solid #bbb;
      text-align: left;

      ul, ol {
        margin-bottom: 0; } }

    th {
      background: rgba(0, 0, 0, 0.05);
      padding: 10px; } }

  // ------------------- LISTINGS -------------------
  ul.toc {
    margin-bottom: 12px;
    margin-right: 12px;
    margin-left: 0;
    display: table;
    font-size: var(--wiki-toc-ul-font-size);

    .section-nav {
      margin-bottom: 0;
      margin-left: 12px; }
    &.right {
      float: right;
      margin-left: 12px;
      margin-right: 0;
      width: auto; }
    &.left {
      float: left;
      margin-right: 12px;
      margin-left: 0;
      width: auto; }
    a {
      font-weight: normal;
      background-repeat: no-repeat;
      background-position: 0% 60%;
      &.icon-context:before {
        padding: 3px 3px 0 0; } }
    li {
      list-style-type: none; } }

  ul li {
    word-wrap: break-word; }


  // ------------------- GENERAL -------------------
  .external.icon-context:before {
    padding: 4px 4px 0 0; }

  fieldset.collapsible legend {
    font-weight: bold;
    font-size: var(--wiki-toc-header-font-size); }
  img {
    vertical-align: middle; } }

a.wiki-anchor {
  display: none;
  text-decoration: none;
  font-size: 16px;
  vertical-align: middle;
  padding-right: 2px;

  &:hover {
    color: #aaa !important;
    text-decoration: none; } }

h1:hover, h2:hover, h3:hover {
  a.wiki-anchor {
    display: inline;
    color: #ddd; } }

.wiki {
  p {
    margin-bottom: 1em;
    font-size: var(--wiki_default-font-size); }

  .quick_info .label {
    background: none;
    color: #000;
    font-weight: bold;
    font-size: var(--wiki-default-font-size); }

  .wiki-title {
    @include text-shortener;
    max-width: 100%; } }

.wiki.wiki-content {
  overflow: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  @include styled-scroll-bar; }

.wiki--content--attribute {
  .form--field-container,
  .form--text-area-container {
    max-width: 100%; } }

#wiki_page_parent_id {
  overflow: auto; }

.toolbar-container ~ .wiki-content {
  margin-top: 0; }

.wiki-version--details {
  .navigate-left {
    margin: 0 1.5rem 0 0; }
  .navigate-right {
    margin: 0 0 0 1.5rem; }
  span {
    vertical-align: middle; } }

body.controller-wiki {

  .ck-content {
    min-height: 25vh; }

  #breadcrumb ul.breadcrumb li.first-breadcrumb-element a {
    display: inline;
    width: auto; } }


@include breakpoint(680px down) {
  .toolbar-container ~ .wiki-content {
    margin-top: 0; } }
